(function ($) {

    var contactForm = $('#contact-form');
    if(contactForm.size() == 0) {
        return;
    }

    function resetForm() {
        // Remove possible success changes to the form.
        $('.Contact__form__alert').hide();

        // Remove possible error changes to the form.
        contactForm.removeClass('has-error');
        $('.Contact__help-block').hide();
    }

    function onSuccess(data) {
        // Remove any possible previous errors or messages.
        resetForm();

        // Show the alert with the success message.
        var alert = $('.Contact__form__alert');
        alert.text(data.message);
        alert.show();

        // Empty the form.
        contactForm[0].reset();
    }

    function onError(result) {
        // Remove any possible previous errors or messages.
        resetForm();

        // Parse the response.
        var response = JSON.parse(result.responseText);

        // Set the form state to 'has-error'.
        contactForm.addClass('has-error');

        // Show all error messages.
        $.each(response, function (key, message) {
            var errorBlock = $('.Contact__help-block--' + key);
            errorBlock.text(message);
            errorBlock.show();
        });
    }

    contactForm.submit(function (evt) {

        // Get the information we need to send the request.
        var url     = contactForm.attr('action');
        var method  = contactForm.attr('method') || 'GET';
        var data    = contactForm.serialize();

        // Execute the request.
        $.ajax({
            type:       method,
            url:        url,
            data:       data,
            dataType:   'json',
            success:    onSuccess,
            error:      onError
        });

        // Prevent the actual execution of the form.
        evt.preventDefault();
    });

})(jQuery);