//// To work with the CookiesOK browser extension. Note: Keep in global context.
function CookiesOK() {
    // Hide the cookie message.
    $('.Cookies').hide();
}
//
(function ($) {

    var acceptButton = $('.Cookies__accept-button');

    function acceptCookies() {

        //// Hide the cookie message.
        $('.Cookies').hide();

        //// Make sure it isn't displayed in the future by setting a cookie.
        var d = new Date();
        d.setTime(d.getTime() + 31536000000); // = 365*24*60*60*1000 ms = one year from now.
        document.cookie = 'accept_cookies=1; expires=' + d.toUTCString();
    }

    // Bind a event listener to the accept button.
    // acceptButton.click(acceptCookies);
    acceptButton.on('click', acceptCookies);

})(jQuery);