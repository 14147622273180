//var slideout = new Slideout({
//    'panel': document.getElementById('slideout-panel'),
//    'menu': document.getElementById('slideout-menu'),
//    'padding': 275,
//    'tolerance': 70,
//    'side': 'right'
//});

$(document).ready(function () {
    
    activateDropDowns();
    activateNavBarFunctions();
    startGeneralAnimations();
    resizeListener();
    setNavigation();

    $(window).scroll(function(){
       if($('body').scrollTop() >= 1){
           $('.Masthead').addClass('Masthead--scroll');
       }
       else{
           $('.Masthead').removeClass('Masthead--scroll');
       }
    });

    $('.toggle-button').on('click', function () {
        slideout.toggle();
    });

    $('.reset--waypoint').on('click', function () {
        setTimeout(forceResize, 400);
    });

    $('.mobile-back').on('click', function () {
        slideout.close();
    });

    $('.slideout-menu--open--languages').on('click', function () {
        $("#slideout-menu--languages").css("display", "block");
        $("#slideout-menu--languages")
            .velocity({right: 0}, 200)
    });

    $('.slideout-menu--close--languages').on('click', function () {
        $("#slideout-menu--languages")
            .velocity({right: -275}, 200)
        $("#slideout-menu--languages")
            .velocity({display: none}, 200)
    });

    $('.toggle-button').on('click', function () {
        slideout.enableTouch();
    });

    $('.disable--mobile--menu').on('click', function () {
        slideout.disableTouch();
    });

    //slideout.on('beforeopen', function () {
    //    $(".slidein-darkness").addClass('slideout-panel-darkness');
    //    $(".Masthead").css("position", "absolute");
    //    $(".Masthead").css("top", $(window).scrollTop());
    //});
    //
    //slideout.on('beforeclose', function () {
    //    $(".slidein-darkness").removeClass('slideout-panel-darkness');
    //    $(".Masthead").css("top", "inherit");
    //    $(".Masthead").css("position", "fixed");
    //});

    $('#js_Product-detail__contact-button').on('click', '', function () {
        $('#js_Product-detail__contact-form').toggle(500);
    });
});

function setNavigation() {
    var path = window.location.pathname;
    path = path.replace(/\/$/, "");
    path = decodeURIComponent(path);

    $(".Masthead__menu-items__item__link").each(function () {
        var href = $(this).attr('href');
        if (path.substring(0, href.length) === href) {
            $(this).closest('.Masthead__menu-items__item').addClass('Masthead__menu-items__item__link-active');
        }
    });
}

function forceResize() {
    $(window).trigger('resize');
}

function activateNavBarFunctions() {
    // caching
    var navBarBackground = $(".background-header-fading-color");
    activateNavBarBackgroundFadingByScroll(navBarBackground);
    activateNavBarSearch(navBarBackground);
}

function startGeneralAnimations() {
    var FloatingBlocks = $(".Transition--slide-in");
    var MainTitle = $(".HeaderFullWidth__title--holder");
    var TopNavBar = $(".cover-container");

    var sequence = [
        {e: TopNavBar, p: 'transition.fadeIn', o: {stagger: 300, duration: 500}},
        {e: MainTitle, p: 'transition.slideUpBigIn', o: {stagger: 300}},
        {e: FloatingBlocks, p: 'transition.slideUpBigIn', o: {delay: 250, sequenceQueue: false}}
    ];

    $.Velocity.RunSequence(sequence);
}


/**
 *
 */
function activateNavBarBackgroundFadingByScroll(navBarBackground) {
    $(function () {
        handleNavBarFadingByWindowScrollTop(navBarBackground);
        $(window).scroll(function () {
            handleNavBarFadingByWindowScrollTop(navBarBackground);
        });
    });
}

/**
 *
 */
function activateNavBarSearch(navBarBackground, navBarMenu) {
    var navBarMenu = $(".Masthead__menu-items");
    var navBarSearchButtonIcon = $('.Masthead__menu-right__search__button__icon');
    var navBarSearchInput = $(".Masthead__menu-right__search__input");

    function handleNavBarBackgroundFading() {
        var navBarBackgroundFadingStateHold = parseInt(navBarBackground.attr('data-faded-in-hold'));

        if (navBarBackgroundFadingStateHold === 0) {
            navBarBackground.attr('data-faded-in-hold', 1);
            NavBarBackgroundTransition(navBarBackground, 1);
            return;
        }
        navBarBackground.attr('data-faded-in-hold', 0);
        handleNavBarFadingByWindowScrollTop(navBarBackground);
    }

    function handleNavBarMenuFading() {
        var navBarMenuVisibleState = parseInt(navBarMenu.attr('data-menu-visible'));
        var fadeSpeed = 100;
        if (navBarMenuVisibleState === 0) {
            navBarMenu.attr('data-menu-visible', 1);
            navBarMenu.fadeTo(fadeSpeed, 1, function () {
            });
            navBarSearchInput.fadeTo(fadeSpeed, 0, function () {
            });
            $(".slidein-darkness").fadeOut(300, function () {
                $(this).removeClass("slideout--searchbg");
            });
            navBarSearchInput.removeClass('Masthead__menu-right__search__input--active');
            $(".hidden--search").addClass('search--show');
            $(".search--show").removeClass('hidden--search');
            $(".icon-close--navigation").addClass('icon-search--navigation');
            $(".icon-search--navigation").removeClass('icon-close--navigation');
            slideout.enableTouch();
            return;
        }
        navBarMenu.attr('data-menu-visible', 0);
        navBarMenu.fadeTo('slow', 0, function () {
        });
        navBarSearchInput.fadeTo(fadeSpeed, 1, function () {
        });
        navBarSearchInput.addClass('Masthead__menu-right__search__input--active');
        $(".slidein-darkness").fadeIn(300, function () {
            $(this).addClass("slideout--searchbg");
        });
        $('.Masthead__menu-right__search__input--active').focus();
        slideout.disableTouch();
        $(".search--show").addClass('hidden--search');
        $(".hidden--search").removeClass('search--show');
        $(".icon-search--navigation").addClass('icon-close--navigation');
        $(".icon-close--navigation").removeClass('icon-search--navigation');
    }

    navBarSearchButtonIcon.on('click', function (e) {
        handleNavBarBackgroundFading();
        handleNavBarMenuFading();
        return false;
    });
}

/**
 *
 */
function handleNavBarFadingByWindowScrollTop(navBarBackground) {
    if ($(window).scrollTop() > 5) {
        NavBarBackgroundTransition(navBarBackground, 1);
        return;
    }
    NavBarBackgroundTransition(navBarBackground, 0);
}

/**
 *
 */
function NavBarBackgroundTransition(navBarBackground, fadeIn) {
    var navBarBackgroundFadedInHoldState = parseInt(navBarBackground.attr('data-faded-in-hold'));
    if (fadeIn === 1) {
        if (!navBarBackground.hasClass("Effect--fading")) {
            navBarBackground.addClass("Effect--fading");
        }
        return;
    }
    if (navBarBackgroundFadedInHoldState === 1) {
        return;
    }
    navBarBackground.removeClass("Effect--fading");
}

/**
 *
 */
function activateDropDowns() {
    activateDropDown($('.Button__dropdown'));
    activateDropDown($('.Dropdown__languages'), 300);
}

function activateDropDown(elm, speed) {
    if (empty(speed)) speed = 400;
    elm.on('show.bs.dropdown', function (e) {
        $(this).find('.dropdown-menu').first().stop(true, true).slideDown(speed);
    });
    elm.on('hide.bs.dropdown', function (e) {
        $(this).find('.dropdown-menu').first().stop(true, true).slideUp(speed);
    });
}

// BASE
/**
 *
 * @param mixed_var
 * @returns {boolean}
 */
function empty(mixed_var) {
    "use strict";
    var undef, key, i, len;
    var emptyValues = [undef, undefined, null, false, 0, '', '0'];
    for (i = 0, len = emptyValues.length; i < len; i++) {
        if (mixed_var === emptyValues[i]) {
            return true;
        }
    }
    if (typeof mixed_var === 'object') {
        for (key in mixed_var) {
            return false;
        }
        return true;
    }
    return false;
}


/**
 *
 */
function handleNavBarFadingByWindowScrollTop(navBarBackground) {
    if ($(window).scrollTop() > 5) {
        NavBarBackgroundTransition(navBarBackground, 1);
        return;
    }
    NavBarBackgroundTransition(navBarBackground, 0);
}

/**
 *
 */
function NavBarBackgroundTransition(navBarBackground, fadeIn) {
    var navBarBackgroundFadedInHoldState = parseInt(navBarBackground.attr('data-faded-in-hold'));
    if (fadeIn === 1) {
        if (!navBarBackground.hasClass("Effect--fading")) {
            navBarBackground.addClass("Effect--fading");
        }
        return;
    }
    if (navBarBackgroundFadedInHoldState === 1) {
        return;
    }
    navBarBackground.removeClass("Effect--fading");
}

/**
 *
 */
function activateDropDowns() {
    activateDropDown($('.Button__dropdown'));
    activateDropDown($('.Dropdown__languages'), 300);
}

function activateDropDown(elm, speed) {
    if (empty(speed)) speed = 400;
    elm.on('show.bs.dropdown', function (e) {
        $(this).find('.dropdown-menu').first().stop(true, true).slideDown(speed);
    });
    elm.on('hide.bs.dropdown', function (e) {
        $(this).find('.dropdown-menu').first().stop(true, true).slideUp(speed);
    });
}

function toggleStickyFooter(){
    if ($("body").height() < $(window).height()) {
        $('.Footer').addClass("Utils__Footer--sticky");
    }
    else {
        $('.Footer').removeClass("Utils__Footer--sticky");
    }
}

function resizeListener(){
    $(window).on("resize", function(){
        toggleStickyFooter();
    })
}



// BASE
/**
 *
 * @param mixed_var
 * @returns {boolean}
 */
function empty(mixed_var) {
    "use strict";
    var undef, key, i, len;
    var emptyValues = [undef, undefined, null, false, 0, '', '0'];
    for (i = 0, len = emptyValues.length; i < len; i++) {
        if (mixed_var === emptyValues[i]) {
            return true;
        }
    }
    if (typeof mixed_var === 'object') {
        for (key in mixed_var) {
            return false;
        }
        return true;
    }
    return false;
}
