/**
 * Created by harmjan on 10/02/16.
 */
$(document).ready(function () {
    $(".Socialmedia-share-button").on('click', '', function (e) {
        jQuery(".Socialmedia-share-button").on("touchmove", true);
        var width = 575,
            height = 400,
            left = ($(window).width() - width) / 2,
            top = ($(window).height() - height) / 2,
            url = this.href,
            opts = 'status=1' +
                ',width=' + width +
                ',height=' + height +
                ',top=' + top +
                ',top=' + top +
                ',left=' + left;

        window.open(url, '', opts);

        return false;

    });
});
